import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import turtles from "../assets/images/turtles.png";
import getImages from "./GetImages";
import artistsList from "./ArtistsArray";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function BigGallery() {
  const randomImages = getImages();

  const urlImages = artistsList.map((artist) => {
    const filterImages = randomImages.filter((image) => {
      return image.includes(artist.files);
    });
    const artistURL = filterImages.map((image) => {
      return { name: image, url: artist.url };
    });
    return artistURL;
  });

  const newUrlImages = urlImages.sort(() => {
    return 0.5 - Math.random();
  });

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Aiea | Our Artwork</title>
      </Helmet>
      <div className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section_title text-center pb-30">
              <h4 className="title">Our Artwork</h4>
              <img className="turtles" alt="turtles" src={turtles} />
            </div>
            <div style={{ textAlign: "center" }} className="pb-30">
              <h6>
                We have a gallery of some of the most memorable tattoos we've
                done over the years, so feel free to browse through them and see
                if there's something that catches your eye!
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          {newUrlImages.map((image) => {
            return image.map((item) => {
              return (
                <div className="col-6 col-md-3 col-lg-2 mb-20" key={item.name}>
                  <Link to={item.url}>
                    <LazyLoadImage
                      src={item.name}
                      effect="blur"
                      alt="image loading"
                    />
                  </Link>
                </div>
              );
            });
          })}
        </div>
      </div>
    </>
  );
}

export default BigGallery;
