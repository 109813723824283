import React from "react";
import {
  Video,
  Locations,
  Artists,
  About,
  Services,
  // MerchandiseHome,
} from "../components";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <div className="position-relative homeAdjust">
        <Video />
        <About />
        <Services />
        <Artists />
        <Locations />
        {/* <MerchandiseHome /> */}
      </div>
      <Helmet>
        <title>Sacred Art Tattoo Aiea</title>
      </Helmet>
    </>
  );
}

export default Home;
