const artistsList = [
  {
    name: "Shane",
    files: "Shane",
    fullName: "Shane Harvey",
    location: "Aiea",
    pic: "https://sacredarthawaii.com/images/artists/Shane.jpeg",
    about:
      "I've been tattooing here in Kailua, Hawaii since 1995. Im used to a busy street shop setting and I'm acquired to various styles of the trade. I enjoy tattooing 'black n grey', traditional, fun lil' Hawaii souvenir pieces and various styles of custom work. I am also fully accustomed to all of your Google and Pinterest image needs.",
    instagram: "https://instagram.com/shaneharveytattoos",
    email: "",
    facebook: "",
    url: "/gallery/shane",
  },
  {
    name: "Myles",
    files: "Myles",
    fullName: "Myles Mokuahi",
    location: "Aiea",
    pic: "https://sacredarthawaii.com/images/artists/Myles.jpg",
    about:
      "Myles Mokuahi aka 'Baby Mokes.' I've been tattooing for 2 years, been drawing since I first poked outta my mom. My father, Kevin Mokuahi has been in the busines for over 30 years, straight up gangster shit. My main inspiration for getting into tattooing was my dad for sure, no doubt about that. I'll be tattooing until I hit the grace, machine and paper towel in hand!",
    instagram: "https://instagram.com/babymokes",
    email: "",
    facebook: "",
    url: "/gallery/myles",
  },
  {
    name: "Heather",
    files: "Heather",
    fullName: "Heather Baldwin",
    location: "Aiea",
    pic: "https://sacredarthawaii.com/images/artists/heather.jpg",
    about:
      "Growing up, Heather was always enthralled with Art. She studied Art Studio and Graphic Design in College. Heather pursued an apprenticeship at Love Dog Tattoo. After three years studying the tradition and craft of tattooing, she ventured out as a fulltime artist in 2013. Heather has been tattooing for 10 years and spent the last decade continuing her learning of the craft. Her Styles range from Traditional to Black and Grey and has a versatile yet eclectic range of expertise. Drawing in influence from fine art, Heather has incorporated such medium influences as watercolor and pen illustration in her tattoos. Heather simply loves designing and Tattooing.",
    instagram: "https://instagram.com/heatherbaldwintattoos",
    email: "",
    url: "/gallery/heather",
  },
  {
    name: "2Bit",
    files: "2Bit",
    fullName: "Chris Curren, aka 2Bit",
    location: "Aiea",
    pic: "https://sacredarthawaii.com/images/artists/2Bit.jpg",
    about:
      "Chris Curren, aka 2Bit. Originally from Ventura, CA. Moved to Hawaii in 2009 for Bullhead city, AZ and apprenticed under Kevin Read. Been tattooing and piercing for over a decade. Enjoys tattooing traditional, neotraditional, lettering, Polynesian tribal, color realism, black and grey realism, and watercolor. Pretty much everything except for portraits.",
    instagram: "https://www.instagram.com/Tat2bit/",
    email: "",
    facebook: "",
    url: "/gallery/2bit",
  },
];

export default artistsList;
